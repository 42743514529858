import Rest from '@/services/Rest';

/**
 * @typedef {LogsSystem}
 */
export default class LogsSystem extends Rest {
    /**
     * @type {String}
     */
    static resource = '/logs-system'
}
